<template>
  <el-container id="containers" style="overflow: auto;">
    <el-header class="header">
      <div class="flex-title">
        <span class="flex-text"  style="font-weight: bold; letter-spacing:0.1rem">机工智选GPT（试运行版）</span>
      </div>
    </el-header>
    <div class="content">
      <div v-show="!isAsk" style="display: flex;">
        <div class="chat-container" style="flex-grow: 1;">
          <img src="" alt="">
          <img src="../assets/gxm.png" class="img-svg">
          <div style="flex-grow: 1; border-radius: 0 2% 2% 2%; background-color: #fff; padding: 1rem;">
            <div>
              <p style="font-size: 1.2rem; font-weight: 600; margin: 0;">您好，我是机工智选产业服务助理</p>
            </div>
            <div>
              <p style="font-size: 1rem; margin:1rem 0 1rem;">您可以试着这样问我：</p>
            </div>
            <div class="gap-2" style="display: flex; flex-direction: column;">
              <div class="ask-row gap-2" v-for="(askRow, rowIndex) in askRows" :key="rowIndex">
                <div class="ask-inner" v-for="(ask, askIndex) in askRow" @click="getTheAsk(ask)" :key="askIndex">
                  {{ ask }}
                </div>
              </div>
            </div>
            <div style="margin-top: 0.5rem;" @click="askPDF()">
              <div class="ask-inner" style="display: flex;">
                请帮我解读一下这个文献
                <img style="width: 1rem; height: 1.2rem; margin-left: 0.5rem; margin-right: 0.2rem;" src="../assets/pdf.png" alt="">
                1-s2.0-S1526612524004080-main.pdf
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="isAsk" style="min-height: 18.75rem; color: #231815; font-size: 1.25rem; padding-top: 0.625rem;" >
        <div v-for="(item, index) in chats" class="chat-container" :key="item.content+index">
          <img v-if="item.type === 'pdf'" :src="userSvg" class="img-user-svg">
          <img v-if="item.type === 'user'" :src="userSvg" class="img-user-svg">
          <img v-if="item.type === 'assistant'" src="../assets/gxm.png" class="img-svg">
          <div v-if="item.type === 'user'" style="flex-grow: 1; border-radius: 0 2% 2% 2%; font-size: 1rem; display:flex; align-items:center">
            {{item.content}}
          </div>
          <div v-if="item.type === 'pdf'">
            <div v-if="item.type === 'pdf'" style="height: 2.4rem; flex-grow: 1; border-radius: 0 2% 2% 2%; font-size: 1rem; display:flex; align-items:center">
              请帮我解读一下这个文献
            </div>
            <div v-if="item.type === 'pdf' && item.pdf === true"  style="flex-grow: 1; border-radius: 0 2% 2% 2%; font-size: 1rem; display:flex; align-items:center">
              <a href=" https://file.chaozhiai.com/1-s2.0-S1526612524004080-main.pdf" target="_blank" style="text-decoration: none;">
                <div style="background: #fff; padding: 0.5rem; display: flex; cursor: pointer;">
                  <img style="width: 1rem; height: 1.2rem; margin-left: 0.5rem; margin-right: 0.2rem;" src="../assets/pdf.png" alt="">
                  1-s2.0-S1526612524004080-main.pdf
                </div>
              </a>
            </div>
          </div>

          <div v-if="item.type === 'assistant'" style="flex-grow: 1; border-radius: 0 2% 2% 2%; background-color: #fff;">
            <span id="blinkCursor" class="blinkCursor" v-show="item.isBlink">
              &#x258B;
            </span>
            <v-md-editor v-model="item.content" mode="preview" v-show="!item.isBlink"></v-md-editor>
          </div>
        </div>
      </div>
      <hr v-show="showSource">
      <p style="font-size: 1.25rem;" v-show="showSource">回答来源：</p>
      <p v-for="(url,i) in urls" :key="url+i" v-show="showSource">
        <el-link type="success" :href="url"  target="_blank">{{ titles[i] }}</el-link>
      </p>
    </div>
    <div class="chat-ask">
      <div class="chat-ask-inner">
        <div class="chat-input">
          <el-input
            type="textarea"
            class="chat-el-input"
            placeholder="请使用完整的句子描述您的问题或需求，这样我们可以更好地帮助您。"
            @keydown.enter.native="handleKeyDown($event)"
            v-model="userInput"
            maxlength="150"
            show-word-limit
          ></el-input>
          <div class="chat-submit" :class="{'active': userInput !== '', 'notAllow': userInput === ''}" @click="sendPost()">
            <svg t="1697530419521" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2340" width="1.8rem" height="1.8rem"><path d="M851.2 166.4c-12.8-6.4-25.6-6.4-32 0l-640 320C166.4 486.4 160 499.2 160 512s6.4 25.6 12.8 25.6l153.6 96c12.8 6.4 25.6 6.4 38.4-6.4L704 320l12.8 6.4-307.2 326.4c-6.4 6.4-6.4 12.8-6.4 19.2l0 140.8c0 12.8 6.4 25.6 19.2 32 12.8 6.4 25.6 0 32-6.4l76.8-76.8 153.6 102.4c6.4 6.4 12.8 6.4 19.2 6.4 6.4 0 6.4 0 12.8 0 12.8-6.4 19.2-12.8 19.2-25.6l128-640C864 185.6 864 172.8 851.2 166.4z" p-id="2341" :fill="userInput !== '' ? '#fff' : '#d9d9e3'"></path></svg>
          </div>
        </div>
      </div>
      <div style="font-size: 0.75rem; text-align: center; padding-top: 0.5rem; padding-bottom: 0.5rem; color: #aeadad;">请遵守国家相关法律来使用本系统。所有内容均由AI大模型输出，仅供参考，不代表我们的态度或观点</div>
    </div>
  </el-container>
</template>

<script>
import kefuSvg from "@/assets/kefu.svg";
import userSvg from "@/assets/user.svg";
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { PDFAnswer } from '../utils/handlePDF';

export default {
  name: "HomePage",
  props: {},
  data() {
    return {
      kefuSvg,
      userSvg,
      userInput: "",
      answer:"",
      PDFAnswer: PDFAnswer, // 来自PDF的回答
      chats:[], // 记录回答
      titles:[],
      urls:[],
      isResult: false, //阻止二次点击
      isAsk:false, // 是否显示提问
      showSource: false, // 展示来源那部分
      replace: [], // 判断网址
      askRows: [
        ["我想了解一下定向能量沉积DED最近的技术发展", "3D打印最新的生物医疗领域应用有哪些？"],
        ["最近3D打印机有哪些进展", "我想了解增减材混合制造的相关信息"]
      ],
    };
  },

  methods:{
    // 滚动到底部
    srcollToBottom() {
      this.$nextTick(() => {
        const container = document.getElementById('containers')
        container.scrollTop = container.scrollHeight;
      });
    },

    getTheAsk(ask){
      // 获取提问
      this.userInput = ask;
    },

    remoteBlink(){
      // 去除光标
      let elements = document.querySelectorAll('.vuepress-markdown-body');
      // 遍历每个匹配的元素
      elements.forEach(function(element) {
        let lastChild = element.lastElementChild;
        if (lastChild) {
          lastChild.classList.add('no-blink');
        }
      });
    },

    handleKeyDown() {
      // 处理按键提问
      let e = window.event || arguments[0];
      if (
        (e.keyCode == 13 || e.key == "Enter" || e.code == "Enter") &&
        e.shiftKey
      ) {
        // 按下 Shift+Enter 键
      } else if (e.keyCode == 13 || e.key == "Enter" || e.code == "Enter") {
        // 按下 Enter 键
        e.returnValue = false; // 阻止默认行为（发送消息）
        this.sendPost();
        return false;
      }
    },


    sendPost(){
      console.log("发送")
      if (!this.userInput) {
        this.$message({
          message: "请输入查询信息",
          type: 'warning',
          duration: 1500
        });
        return
      }
      if(this.userInput.length > 150){
        this.$message({
          message: "提问不允许超过150个字符",
          type: 'warning',
          duration: 1500
        });
        return
      }
      if(this.isResult){
        console.log("二次点击了")
        return
      }
      this.replace = []
      this.isAsk = true
      this.isResult = true
      const ask = {type:"user", content: this.userInput}
      this.chats.push(ask)
      const answer = {type:"assistant",content:"",isBlink:true}
      this.chats.push(answer)
      this.srcollToBottom()

      const data = {
        "model": "/public/home/ac1dokyd9u/baidudownload/checkpoint-200",
        "stream": "True",
        "max_tokens": 2048,
        "temperature": 0.6,
        "top_p": 0.5,
        "frequency_penalty":0.6,
        "presence_penalty":0.1,
        "messages": [
            {
                "role": "user",
                "content": this.userInput
            }
        ]
      }

      this.userInput = ""  // 发送之后就清空对话
      try {
        fetchEventSource("/openai", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            openWhenHidden: true,
            async onopen(response) {
              if (response.ok && response.status === 200) {
                console.log("连接成功")
            } else {
                console.error("连接失败")
            }
            },
            onmessage: async (event) => {
              // console.log(event)
              if (event.data === '[DONE]') {
                this.isResult = false
                console.log('回答结束')
                this.$nextTick(() => {
                  this.remoteBlink()
                })
                return
              }
              if (event.data) {
                // 只要后端传来数据就可以停止闪烁
                this.chats[this.chats.length - 1].isBlink = false
                // 将字符串解析为 JSON 对象
                const responseObject = JSON.parse(event.data);
                if(responseObject.ADD_OUR_URL){
                  // 处理人名出现链接的情况
                  const pattern = /请您.*自行.*百度.*政府官网/;
                  const isOrderCorrect = pattern.test(this.chats[this.chats.length - 1].content);
                  if(!isOrderCorrect){
                    this.chats[this.chats.length - 1].content += responseObject.ADD_OUR_URL
                    this.srcollToBottom()
                  } else {
                    this.srcollToBottom()
                  }
                } else if(responseObject.choices[0].finish_reason === "url") {
                  // 后台传过来url数据进行保存
                  this.replace = JSON.parse(responseObject.choices[0].delta.content)
                } else {
                  let content = responseObject.choices[0].delta.content;

                  // TODELETE: 去除代码块的缩进，后面可能可以去掉
                  const indentationPattern = /^ {4}|^\t/;
                  if(indentationPattern.test(content)){
                    content = ""
                  }

                  if(content) {
                    // 传过来的文字内容处理
                    this.srcollToBottom()
                    this.chats[this.chats.length - 1].content += content
                    if(this.replace.length > 0){
                      // console.log("不是空的")
                      for(let obj of this.replace) {
                        for (let key in obj) {
                          const regex = new RegExp(key, 'g')
                          this.chats[this.chats.length - 1].content = this.chats[this.chats.length - 1].content.replace(regex, '<' + obj[key] + '>')
                        }
                      }
                    }
                    this.answerCacheForGraph += content
                  }
                }
              }
            },
            async onerror(error) {
              console.error(error);
            },
            async onclose() {
              console.log("关闭连接")
            }
        });
      } catch (error) {
          console.error(error);
      }
    },

    askPDF() {
      this.replace = []
      this.isAsk = true
      this.isResult = true
      const ask = {type:"pdf", content: this.userInput, pdf: true}
      this.chats.push(ask)
      const answer = {type:"assistant",content:"",isBlink:true}
      this.chats.push(answer)
      this.srcollToBottom()

      this.$nextTick(() => {
        setTimeout(() => {
          this.chats[this.chats.length - 1].isBlink = false
          // 设置添加速度（每次添加的间隔时间，单位：毫秒）
          const typingSpeed = 20;

          // 要添加的完整字符串
          // 获取要添加的字符串的长度
          const totalLength = this.PDFAnswer.length;

          // 初始化计数器
          let counter = 0;

          // 每隔一定时间向目标字符串中添加一个字符
          const intervalId = setInterval(() => {
              // 获取当前应该添加的字符
              const charToAdd = this.PDFAnswer[counter];
              // 将字符添加到目标字符串中
              this.chats[this.chats.length - 1].content += charToAdd;
              
              this.srcollToBottom() // 移动到底部
              // 更新计数器
              counter++;
              
              // 如果已经添加完整个字符串，清除定时器
              if (counter === totalLength) {
                  // 回答结束
                  this.isResult = false
                  this.$nextTick(() => {
                    this.remoteBlink()
                  })
                  clearInterval(intervalId);
              }
          }, typingSpeed);
        }, 1600); // 两秒的延迟

      })
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header{
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem !important;
  z-index: 9999;
}
.chat-container{
  display: flex; 
  flex-direction: row; 
  gap: 1rem; 
  margin-bottom:1rem; 
  margin-top: 1rem;
}
.flex-img{
  width: 4.2rem;
  height: 3.125rem;
}
.flex-text{
  color: #fff; 
  font-size: 1.5625rem; 
  line-height: 3.125rem;
}
@media (max-width: 300px) {
  .flex-text {
    font-size: 1.25rem;
  }
  .flex-img{
    width: 3.36rem;
    height: 2.5rem;
  }
}
@media (max-width: 250px) {
  .flex-text {
    font-size: 1rem;
  }
  .flex-img{
    width: 2.688rem;
    height: 2rem;
  }
}
@media (max-width: 220px) {
  .flex-text {
    font-size: 0.8rem;
  }
}
  
.img-svg{
  width: 1.8rem; 
  height: 1.8rem; 
  border-radius: 50%; 
  background-color:#0191e7; 
  padding: 0.1rem;
}
.img-user-svg{
  width: 1.6rem; 
  height: 1.6rem; 
  border-radius: 50%; 
  background-color:#0191e7; 
  padding: 0.2rem;
}
.gap-2{
  gap: 0.5rem;
}
.ask-row{
  display: flex; 
  flex-direction: row; 
  flex: 1 1 0%;
}
.ask-inner{
  flex: 1 1 0%;
  padding: 0.75rem;
  background-color:#F5F7FA;
  border-radius: 0.375rem;
  cursor: pointer;
}

.ask-inner:hover{
  background-color:#d8e2f0;
}

.blinkCursor {
  display: inline-block;
  width: 0.5rem;
  height: 1.6rem;
  padding: 0.4rem 0.8rem;
  vertical-align: baseline;
  animation: blink 1s infinite;
}
@keyframes blink {
  50% {
    opacity: 0;
  }
}
.el-container {
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.05);
}
.content {
  margin: 0 auto 8.2rem auto;
  transform: translateX(-1.1rem);
  width: 57.5%;
  padding: 0rem 1.25rem;
  border-radius: 1.25rem;
  /* background-color: rgba(0, 0, 0, 0.05); */
}

@media (max-width: 768px) {
  .content {
      margin: 0 auto 13rem auto;
      width: 90%;
      transform: translateX(0rem);
    }
}

.el-header {
  background-color: #0191e7;
  color: #333;
  text-align: center;
  display: flex;
  flex-direction: column;
  /* line-height: 60px; */
}
.flex-title{
  display: flex;
  flex-direction: row;
  margin: auto;
}

.search{
  color: #8F8FA5; 
  font-size: 1.25rem;
  font-weight:bolder;
  width: 1.5rem;
}
.search:hover{
  color: #fff; 
}
.chat-ask{
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 888;
  width: 100%;
  position: fixed;
  bottom: 0;
  margin: auto;
  padding-top: 1.5rem;
  padding-bottom: 0.25rem;
  background-image: linear-gradient(180deg, rgba(187, 189, 193, 0),#F2F2F2 10%);
}
.chat-ask-inner {
  width: 53.4%;
  margin: 0 auto auto auto;
}
@media (max-width: 768px) {
  .chat-ask-inner {
    width: 95%;
}
}
.chat-input {
  position: relative;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.2) 0px 0px 15px 0px;
  border-radius: 4px;
  background-color: white;
}
.chat-submit {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: 0.8rem;
  bottom: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
}

/* 当屏幕宽度小于等于768px时 */
@media (max-width: 768px) {
  .chat-submit {
      right: 1.2rem;
      width: 1.8rem;
      height: 1.8rem;
    }
}
.chat-submit.active {
  background-color: #85abdf; /* 设置非空时的背景颜色 */
  cursor: pointer;
}
.chat-submit.notAllow {
  cursor: not-allowed;
}
.chat-submit-img {
  margin-right: 0.125rem;
  margin-bottom: 0.125rem;
}
.el-divider--vertical {
  width: 0.125rem;
  height: 3em;
  margin: 0 1.125rem;
}

.el-link.el-link--success{
  color: #5375d3;
}
.el-link{
  font-size: 1.125rem;
}

.chat-input ::-webkit-scrollbar,
.content ::-webkit-scrollbar{
  width: 8px;
}
.chat-input ::-webkit-scrollbar-thumb,
.content ::-webkit-scrollbar-thumb{
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.chat-input ::-webkit-scrollbar-track,
.content ::-webkit-scrollbar-track{
    border-radius: 10px;
    background-color: rgb(218, 218, 218);
}.chat-input ::-webkit-scrollbar-thumb:hover,
.content ::-webkit-scrollbar-thumb:hover {
  background-color: #9e9c9c;
}
</style>
<style>
.vuepress-markdown-body:not(.custom){
  padding:1rem 1rem !important;
}

.vuepress-markdown-body>:not(ol):not(ul):not(pre):last-child::after {
  --webkit-animation: blinks 1s steps(3, start) infinite;
  animation: blinks 1s steps(3, start) infinite;
  content: "\258B";
  margin-left: 0.25rem;
  vertical-align: baseline;
}

.vuepress-markdown-body h5 {
  margin: 0.6rem 0 !important;
}

@keyframes blinks {
  50% {
    opacity: 0;
  }
}

.no-blink::after {
  content: none !important;
}

.v-md-editor,.v-md-preview,.vuepress-markdown-body{
  background: none !important;
}

/* 用于换行长单词、url */
.vuepress-markdown-body{
  word-wrap: break-word;
  font-size: 1rem !important;
}

.vuepress-markdown-body h3{
  font-size: 1rem !important;
}

.vuepress-markdown-body h2 {
  font-size: 1.25rem !important;
}

.vuepress-markdown-body h1 {
  font-size: 1.5rem !important;
}
.chat-el-input .el-textarea__inner {
  font-family: Söhne, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto,
    Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica Neue, Arial,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji !important;
  /* overflow: hidden !important; */
  resize: none !important;
  max-height: 5.1rem !important;
  border: 0;
  margin: 0;
  min-height: 5.1rem !important;
  padding: 0.3rem 3.5rem 0.3rem 1rem;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  font-size: 1rem;
}

.el-textarea .el-input__count {
  font-size: 0.9rem !important;
  top: 0.3rem !important;
  right: 0.7rem !important;
}

@media (max-width: 768px) {
  .chat-el-input .el-textarea__inner {
    max-height: 6.6rem !important;
    min-height: 6.6rem !important;
  }
}
.el-icon-loading {
  font-size: 3.125rem  !important;
}
.el-loading-spinner .el-loading-text {
  color: #7895e4 !important;
  font-size: 1.25rem  !important;
}
.el-loading-spinner i{
  color: #7895e4  !important;
}
</style>
